
const usersList = [

  {
    label: 'На усмотрение руководителя',
    value: "24cfbf0d-a0f5-4206-a40c-c32ff0e8d122",
    tgId: 85252645
  },

  {
    label: 'Артем Андриянов',
    value: "6a207040-1dc2-49b7-be53-6fbb1425de48",
    tgId: 465573856
  },


  {
    label: 'Александр Борисов',
    value: "f29cc9d9-a6fe-4ade-8607-925c403bbbb0",
    tgId: 6378089936
  },

  {
    label: 'Денис Габбасов',
    value: "103fd61a-7459-46c4-b8d5-5d6877091537",
    tgId: 265695055

  },

  {
    label: 'Александр Карпов',
    value: "134f54ad-5a17-4c6f-846d-381e3c616799",
    tgId: 422276672

  },


  {
    label: 'Денис Селезнев',
    value: "0e76e63f-e26f-4647-8681-baaac9a013b2",
    tgId: 438616660
  },

  {
    label: 'Динислам Ахметьянов',
    value: "05f0949c-db6d-4403-858f-f0652f4d604c",
    tgId: 69157740
  },

  {
    label: 'Линар Галлямов',
    value: "565dd28c-5b06-409c-874c-b3adacda73c4",
    tgId: 801964257
  },

  {
    label: 'Илья Кириллов',
    value: "7b77e9a6-3a10-41af-9bc0-0b06104b6002",
    tgId: 289782566
  },

  {
    label: 'Максим Карпухин',
    value: "b373dad0-65de-459e-8ccb-be36db125710",
    tgId: 307412203
  },

  {
    label: 'Наиль Ахмедшин',
    value: "97704ee0-8326-4f19-9fb2-10a19b25733e",
    tgId: 735597
  },

  {
    label: 'Руслан Габидуллин',
    value: "280129e1-cfa2-49f6-ae0e-e40c0e707a91",
    tgId: 901944117

  },
]

export default usersList